import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Download from "./views/Download/index";
import DownloadPingfan from "./views/Download/pingfan";
import DownloadFranklingyin from "./views/Download/franklingyin";
import DownloadMookervision from "./views/Download/mookervision";
import DownloadGCKN from "./views/Download/gckn";
import DownloadGoreading from "./views/Download/goreading";

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/download">
            <Download />
          </Route>
          <Route path="/downloadpingfan">
            <DownloadPingfan />
          </Route>
          <Route path="/downloadfranklingyin">
            <DownloadFranklingyin />
          </Route>
          <Route path="/downloadvision">
            <DownloadMookervision />
          </Route>
          <Route path="/downloadgckn">
            <DownloadGCKN />
          </Route>
          <Route path="/gckn">
            <DownloadGCKN />
          </Route>
          <Route path="/downloadgoreading">
            <DownloadGoreading />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
