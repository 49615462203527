import React, { useEffect } from "react";

//updated to 1.5.3
const androidUrl = "https://download.shangshispace.com/nissi.1.8.6.b.125.apk";
const oldAndroidUrl = androidUrl;
const iosUrl = "https://apps.apple.com/us/app/nissispace/id1586014234";
const macUrl = "https://media.medianissispace.com/apk/Nissi.1.5.3.b2.dmg";

function isWeixin() {
  return navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1;
}

function getMobileOperatingSystem() {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Android
  if (/android|Linux/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  // Mac & iPad pro
  if (userAgent.toUpperCase().indexOf("MAC") >= 0) {
    if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
      return "iOS";
    }
    return "Mac";
  }

  return "unknown";
}

function getAndroidVersion(ua) {
  ua = (ua || navigator.userAgent).toLowerCase();
  let match = ua.match(/android\s([0-9\.]*)/i);
  return match ? match[1] : undefined;
}

let deviceType = getMobileOperatingSystem();

const redirectToDownloadUrl = () => {
  // copy deeplink url into clipboard
  // navigator.clipboard.writeText(deepLinkUrl);

  // redirect to download URL
  if (deviceType == "iOS") {
    window.location.href = iosUrl;
  } else if (deviceType == "Mac") {
    window.location.href = macUrl;
  } else {
    let androidVersion = getAndroidVersion();
    if (androidVersion) {
      let androidVersionNum = parseInt(getAndroidVersion(), 10);
      if (androidVersionNum > 5) {
        window.location.href = androidUrl;
      } else {
        window.location.href = oldAndroidUrl;
      }
    } else {
      window.location.href = androidUrl;
    }
  }
};

const Download = () => {
  const isInWexin = isWeixin();

  useEffect(() => {
    if (!isInWexin) {
      // open deep link
      let urlArr = window.location.href.split("download");
      // get deep link params
      if (urlArr.length > 1 && urlArr[1] !== "" && urlArr[1] !== "/") {
        let deepLinkParams = "/";
        deepLinkParams = urlArr[1];
        if (deepLinkParams.substr(0, 1) !== "/") {
          deepLinkParams = "/" + deepLinkParams;
        }
        let deepLinkUrl = "nissi:/" + deepLinkParams;
        window.location = deepLinkUrl;

        // open download url if app not installed
        setTimeout(function () {
          redirectToDownloadUrl();
        }, 2000);
      } else {
        redirectToDownloadUrl();
      }
    }
  }, []);

  return (
    <div class="download-body">
      {isInWexin && (
        <img
          src="/images/wechat-click-arrow.svg"
          loading="lazy"
          alt=""
          class="wechat-click-arrow"
        />
      )}
      <div class="download-content-wrapper">
        <img
          src={
            isInWexin
              ? "/images/wechat-click-content.svg"
              : "/images/android-download-content.svg"
          }
          loading="lazy"
          alt=""
          class="download-content"
        />
      </div>
    </div>
  );
};

export default Download;
